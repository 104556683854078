import axios from 'axios';
import {store} from '../store'

class ApiService  {       
    getData(apiurl,apidata = {}) {

      const user = store.getState().backend.user;
      const token = user.token ? user.token : false;

        return axios.post(apiurl, apidata, {
          withCredentials: true, 
          headers: {'Authorization': token}
        })
        .then(response => {
            return response.data;
        });

    }
}

const exportData = new ApiService();
export default exportData;