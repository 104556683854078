import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from '../store/backend/actions';
import HelperService from '../services/helper.service';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
//import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import LoginIcon from '@mui/icons-material/Login';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

const Topbar = () => {

  const dispatch = useDispatch();
  const navstate = useSelector(state => state.backend.navstate);
  const user = useSelector(state => state.backend.user);
  const adminuser = useSelector(state => state.backend.adminuser);

  let openNavIcon = navstate !== 'opened' ? <MenuIcon className='icon' onClick={() => { dispatch(GlobalStore.setNavState('opened')) }} /> : <MenuOpenIcon className='icon' onClick={() => { dispatch(GlobalStore.setNavState('closed')) }} />;

  let navigate = useNavigate();
  const changeToAdmin = () => {
    if (adminuser) {
      dispatch(GlobalStore.setUser(adminuser))
      navigate("/");
    }
  }

  const logout = () => {
    dispatch(GlobalStore.setNavState('opened'))
    dispatch(GlobalStore.setUser(false));
    navigate("/");
  }

  return (
    <div className='topbar'>
      <div className="wrapper">
        {openNavIcon}
        <div className='items'>
          <div className="item"><NavLink to="/vorschau"><Tooltip title="Vorschau ansehen" placement="bottom-end" arrow><ManageSearchOutlinedIcon className='icon' /></Tooltip></NavLink></div>
          {HelperService.checkAdmin(user)
            ?
            <div className="item"><Tooltip title="Zum Adminbereich" placement="bottom-end" arrow><LoginIcon className='icon' onClick={() => { changeToAdmin() }}></LoginIcon></Tooltip></div>
            :
            <div className="item"><Tooltip title="Abmelden" placement="bottom-end" arrow><PowerSettingsNewIcon className='icon' onClick={() => { logout() }} /></Tooltip></div>
          }
        </div>
      </div>
    </div>
  )
}

export default Topbar