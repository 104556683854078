import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from '../../store/backend/actions';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminTopbar = () => {


  let navigate = useNavigate();
  const dispatch = useDispatch();
  const navstate = useSelector(state => state.backend.navstate);
  let openNavIcon = navstate !== 'opened' ? <MenuIcon className='icon' onClick={() => { dispatch(GlobalStore.setNavState('opened')) }} /> : <MenuOpenIcon className='icon' onClick={() => { dispatch(GlobalStore.setNavState('closed')) }} />;

  const logoutAdmin = () => {
    dispatch(GlobalStore.setNavState('opened'))
    dispatch(GlobalStore.setUser(false));
    dispatch(GlobalStore.setAdminUser(false));
    navigate("/");
  }


  return (
    <div className='topbar'>
      <div className="wrapper">
        {openNavIcon}
        <div className='items'>
          <div className="item"><Tooltip title="Abmelden" placement="bottom-end" arrow><PowerSettingsNewIcon className='icon' onClick={() => logoutAdmin()} /></Tooltip></div>
        </div>
      </div>
    </div>
  )
}

export default AdminTopbar