import QRCode from "react-qr-code";

const Einloeseapp = () => {
    return (
        <>
            <div className='content'>
                <span className="sectiontitle">Einlöse-App</span>
                <p>Laden Sie sich unsere Einlöse-App ganz bequem auf Ihr Smartphone.</p>
                <div className="appbox first">
                    <div className="title">Android</div>
                    <QRCode className="qrcode" value='https://play.google.com/store/apps/details?id=de.digiwerft.gutscheinscanner' bgColor={"transparent"} />

                </div>
                <div className="appbox">
                    <div className="title">iOS</div>
                    <QRCode className="qrcode" value='https://apps.apple.com/de/app/gutscheinscanner/id6478851516' bgColor={"transparent"} />
                </div>
            </div>
        </>
    )
}

export default Einloeseapp