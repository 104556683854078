import './login.scss'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as GlobalStore from '../../store/backend/actions';
import ApiService from "../../services/api.service";
import { jwtDecode } from 'jwt-decode';

const Login = () => {

  // Random Background
  const [selectedPicture, setSelectedPicture] = useState();
  useEffect(() => {
    if (!selectedPicture) {
      function importAll(r) {
        return r.keys().map(r);
      }
      const images = importAll(require.context('./backgrounds', false, /\.(png|jpe?g|svg)$/));
      const randomIndex = Math.floor(Math.random() * images.length);
      setSelectedPicture(images[randomIndex]);
    }
  }, [selectedPicture])


  // Login

  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);

  if (error) {
    throw error;
  }

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const sendLogin = (e) => {
    e.preventDefault();
    if (username === '' || password === '') {
      setFormError('Bitte alle Felder ausfüllen')
    }
    else {
      const API_URL = global.backendconfig.expressserver.url + '/login';
      const API_DATA = {
        'username': username,
        'password': password
      }
      ApiService.getData(API_URL, API_DATA).then((response) => {
        if (typeof (response.apierror) !== 'undefined') {
          setError(response.apierror);
        }
        else {
          if (response.user) {
            let tokendata = jwtDecode(response.user.token);
            if (tokendata.authorities.includes('SYSTEM_ADMIN') || tokendata.authorities.includes('BACKOFFICE')) {
              dispatch(GlobalStore.setUser(response.user))
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
            else {
              setFormError('Anmeldung fehlgeschlagen')
            }
          }
          else {
            if (response.loginerror) {
              setFormError('Anmeldung fehlgeschlagen')
            }
          }
        }
      }).catch((error) => {
        setError('NO_CONNECTION')
      });
    }
  }

  return (
    <div className="login-wrapper" style={{ backgroundImage: `url(${selectedPicture})` }}>
      <div className='loginbox'>
        <span className='logo'></span>
        <div className='loginform'>
          <form>
            <div>
              <label>Benutzername</label>
              <input type="text" value={username} onChange={(e) => { setFormError(null); setUsername(e.target.value); }} />
            </div>
            <div>
              <label>Passwort</label>
              <input type="password" value={password} onChange={(e) => { setFormError(null); setPassword(e.target.value); }} />
            </div>
            <div>
              {formError ? <span className='error'>{formError}</span> : ''}
              <button type="submit" onClick={(e) => sendLogin(e)} >Anmelden</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login