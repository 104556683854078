const initialState = {
    navstate: 'opened',
    darkmode: false,
    user: false,
    saved: false
  };
  
  const Reducer = (state = initialState, action) => {
      switch (action.type) {       
          case 'SET_APIERROR': {
              return {
              ...state,
              apierror: action.apierror,
              };
          }    
          case 'SET_USER': {
            return {
            ...state,
            user: action.user,
            };
        }       
          case 'SET_NAVSTATE': {
              return {
              ...state,
              navstate: action.navstate,
              };
          }  
          case 'SET_DARKMODE': {
              return {
              ...state,
              darkmode: action.darkmode,
              };
          } 
          case 'SET_SAVED': {
            return {
            ...state,
            saved: action.saved,
            };
        }    
        case 'SET_ADMINUSER': {
            return {
            ...state,
            adminuser: action.adminuser,
            };
        }        
          default: {
              return state;
          }
      }
  };
  
  export default Reducer;
  